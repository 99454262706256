import React from 'react';

import './modal.css';
import $ from 'jquery';


$(function() {
  //open popup
  $('.popup').addClass('is-visible');

  //close popup
  $('.popup').on('click', function(event){
    if( $(event.target).is('.popup-close') || $(event.target).is('.popup') ) {
      event.preventDefault();
      $(this).removeClass('is-visible');
    }
  });
});


export function Notification() {
  return (
    <section>
      <div className="popup" role="alert">

        <div className="popup-container sm:container-mob">
          <a href="#0" className="popup-close img-replace">Close</a>

          <p className="font-bold text-2xl sm:text-3xl">
            Oznámení
          </p>

          <br />

          <p className="text-xl xs:text-xl">
            Z důvodu nemoci do 7.2.2025 zavřeno.
          </p>

        </div>

      </div>

    </section>
  );
}

export default Notification
